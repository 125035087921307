import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledImage = styled(Plaatjie)`
  /* height: auto; */

  @media screen and (max-width: 1200px) {
    height: 100%;
  }
`

const SectionWrapper = styled.section`
  max-width: 1400px;
  min-height: 555px;
  position: relative;

  h1,
  h2,
  h4,
  h5,
  h6 {
    font-size: ${(props) => props.theme.font.size['40']};
    margin-bottom: 2rem;
    @media (max-width: 991px) {
      line-height: ${(props) => props.theme.font.size[40]};
    }
  }

  h3 {
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 5px;
  }

  @media screen and (max-width: 1450px) {
    max-width: 1200px;
  }

  @media screen and (max-width: 1200px) {
    height: fit-content;
  }
`

const CustomCol = styled.div`
  padding: 4.5rem 1.5rem 1.5rem 1.5rem;

  /* @media screen and (min-width: 1200px) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  } */

  @media screen and (max-width: 1200px) {
    padding: 2rem 4rem;
    padding-left: 4rem;
  }

  @media screen and (max-width: 576px) {
    padding: 1.5rem 1.5rem;
  }

  @media (max-width: 991px){
    & iframe {
      height: 187px;
    }
  }

  /* @media (min-width: 991px) {
    padding-top: 3.5rem;
    padding-left: 12rem !important;
    padding-right: 3rem !important;
  } */
`

const ImageWrapper = styled.div`
  /* @media screen and (min-width: 1200px) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  } */

  position: absolute;
  top: 0;
  left: 0;
  width: 900px;
  height: 555px;

  @media screen and (max-width: 1200px) {
    position: relative;
    height: 500px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
  }
`

interface TextWithBigImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithBigImage
}

const TextWithBigImage: React.FC<TextWithBigImageProps> = ({ fields }) => {
  const order: string =
    fields?.view?.split('/')[2] === 'L' ? 'order-md-1' : 'order-md-3'

  return (
    <SectionWrapper className="container p-0 mt-0">
      <div className="row h-100">
        <CustomCol
          className={`col-xl-${
            fields?.view?.split('/')[0]
          } order-md-2 col-12 d-flex align-items-center`}
        >
          <ParseContent content={fields.description} />
        </CustomCol>
        <div
          className={`col-xl-${
            fields?.view?.split('/')[1]
          } mt-md-5 mt-lg-0 col-12 ${order}
            `}
        >
          <ImageWrapper className="d-flex justify-content-center justify-content-xl-start align-items-start">
            <StyledImage image={fields?.image} alt="" className="w-100" />
          </ImageWrapper>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default TextWithBigImage
